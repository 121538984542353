<template>
  <section>
    <validation-observer
      #default="{ handleSubmit, pristine }"
      ref="refFormObserver"
    >
      <!-- SECTION Form -->
      <b-form
        @submit.prevent="handleSubmit(submitHandle)"
      >
        <b-card
          header-tag="header"
          border-variant="info"
          header-bg-variant="light-info"
          header-class="py-1"
          class="border mt-1"
        >
          <!-- ANCHOR header -->
          <template #header>
            <h6 class="m-0">
              {{ $t('promotionUser.create.title') }}
            </h6>
          </template>
          <b-card-body class="p-0">
            <b-row class="mt-1">
              <!-- ANCHOR Employee Code -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionUser.fields.employeeCode')"
                  rules="required"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionUser.fields.employeeCode') }}
                        <span class="text-danger">(*)</span>
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotionDataToAdd.employeeCode"
                      :formatter="upperCaseFormatter"
                      :placeholder="$t('promotionUser.placeholder.employeeCode')"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Source -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionUser.fields.source')"
                  rules="required"
                >
                  <b-form-group
                    class="mb-0"
                    :state="getValidationState(validationContext) === false ? false : null"
                  >
                    <template #label>
                      <div>
                        {{ $t('promotionUser.fields.source') }}
                        <span class="text-danger">(*)</span>
                      </div>
                    </template>
                    <v-select
                      v-model="promotionDataToAdd.source"
                      style="font-size: 1rem;"
                      :options="distributorExtendOptions"
                      :clearable="false"
                      :reduce="val => val.value"
                      :placeholder="$t('promotionUser.placeholder.source')"
                      @close="validationContext.validate"
                    >
                      <template #selected-option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class="d-block text-nowrap"
                          >
                            {{ data.label }}
                          </span>
                        </div>
                      </template>
                      <template v-slot:option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class=" d-block text-nowrap"
                          >
                            {{ data.label }}
                          </span>
                        </div>
                      </template>
                      <template #spinner="{ loading }">
                        <div
                          v-if="loading"
                          style="border-left-color: rgba(88, 151, 251, 0.71)"
                          class="vs__spinner"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                  </b-form-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <!-- ANCHOR maxApply -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionUser.fields.maxApply')"
                  rules="required"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionUser.fields.maxApply') }}
                        <span class="text-danger">(*)</span>
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotionDataToAdd.maxApply"
                      v-remove-non-numeric-chars.allNumber
                      number
                      maxlength="5"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('promotionUser.placeholder.maxApply')"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR promoCode -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionUser.fields.promoCode')"
                  rules="required"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionUser.fields.promoCode') }}
                        <span class="text-danger">(*)</span>
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotionDataToAdd.promoCode"
                      :formatter="upperCaseFormatter"
                      :placeholder="$t('promotionUser.placeholder.promoCode')"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <!-- !SECTION -->

        <!-- ANCHOR Action Buttons -->
        <div class="d-flex mt-1 justify-content-center">
          <!-- Back button -->
          <b-button
            variant="secondary"
            class="mr-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="backHandle"
          >
            {{ $t('back') }}
          </b-button>

          <!-- Clear button -->
          <b-button
            variant="danger"
            class="mx-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="pristine"
            @click="clearHandle"
          >
            {{ $t('clear') }}
          </b-button>

          <!-- Next button -->
          <b-button
            variant="info"
            class="ml-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit"
          >
            {{ $t('save') }}
          </b-button>
        </div>
      </b-form>
      <!-- !SECTION -->
    </validation-observer>
  </section>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BForm,
  // BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

import store from '@/store'
import { distributorExtendOptions } from '@/constants/selectOptions'

import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput, upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import promotionUserStoreModule from '@promotionUser/promotionUserStoreModule'
import usePromotionUserHandle from '@promotionUser/usePromotionUserHandle'
import {
  max, min, required, code,
} from '@validations'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
    // BFormDatepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const PROMOTION_APP_STORE_MODULE_NAME = 'app-promotionUser'

    // Register module
    if (!store.hasModule(PROMOTION_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROMOTION_APP_STORE_MODULE_NAME, promotionUserStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROMOTION_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROMOTION_APP_STORE_MODULE_NAME)
      }
    })

    const blankPromotionData = {
      isActive: true,
      employeeCode: '',
      source: 'QH',
      promoCode: '',
      maxApply: null,
    }

    const promotionDataToAdd = ref(JSON.parse(JSON.stringify(blankPromotionData)))
    const resetPromotionData = () => {
      promotionDataToAdd.value = JSON.parse(JSON.stringify(blankPromotionData))
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetPromotionData)
    const {
      createPromotionUser,
      loading,
    } = usePromotionUserHandle()

    function submitHandle() {
      refFormObserver.value.validate().then(success => {
        if (success) {
          createPromotionUser(promotionDataToAdd.value).then(() => {
            this.$router.push({ name: 'apps-promotionUser-list' })
          })
        }
      })
    }

    function backHandle() {
      this.$router.go(-1)
    }

    function clearHandle() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.clearForm'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) resetForm()
        })
    }

    return {
      promotionDataToAdd,
      refFormObserver,
      getValidationState,

      loading,

      // Validations
      required,
      min,
      max,
      code,

      // Methods
      backHandle,
      submitHandle,
      clearHandle,
      trimInput,
      upperCaseFormatter,

      distributorExtendOptions,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
